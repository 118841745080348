//subrequest page 
import React, { useEffect, useState } from 'react';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'



const SubstituteRequestsPage = () => {
  const [requests, setRequests] = useState([]);
  const [classes, setClasses] = useState([]);
  const [open, setOpen] = useState(false);
  const [newRequest, setNewRequest] = useState({
    classId: '',
    dateTime: null, // Combined date and time
    status: 'open',
    requestingInstructor: '',
    comment: ''
  });
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    API.get('/subRequests')
      .then((response) => {
        setRequests(response.data.docs);
        console.log('Loaded substitute requests:', response.data);
      })
      .catch((error) => {
        console.error("Failed to load substitute requests", error);
      });

    API.get('/classes')
      .then((response) => {
        setClasses(response.data.docs);
        console.log('Loaded classes:', response.data);
      })
      .catch((error) => {
        console.error("Failed to load classes", error);
      });
  }, [isLoggedIn, navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchSubstituteRequests = () => {
    API.get('/subRequests')
      .then((response) => {
        const subsData = response.data.docs; // Adjust based on your actual response structure
        setRequests(subsData);
        console.log('Loaded substitute requests:', response.data); // For debugging
      })
      .catch((error) => {
        console.error("Failed to load substitute requests", error);
      });
  };
  
  const handleAcceptRequest = (requestId) => {
    const updatedRequest = {
      ...newRequest,
      substituteInstructor: user._id // Set the current user as the substitute instructor
    };

    API.put(`/subRequests/${requestId}/accept`, updatedRequest)
      .then((response) => {
        console.log('Request accepted', response.data);
        fetchSubstituteRequests(); // Refresh the list of requests
      })
      .catch((error) => {
        console.error('Error accepting request:', error);
      });
  };
  

  const handleInputChange = (e) => {
    setNewRequest({ ...newRequest, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (!newRequest.classId || !newRequest.dateTime) {
      alert('Please fill all the required fields.');
      return;
    }

    API.post('/subRequests', newRequest)
      .then((response) => {
        console.log('New request added:', response.data); // For debugging
        fetchSubstituteRequests(); // Fetch all requests again

        const updatedRequest = {
          ...response.data,
          class: classes.find(cls => cls._id === response.data.class) || response.data.class, 
          requestingInstructor: user._id
        };
  
        setRequests([...requests, updatedRequest]);
        handleClose();
      })
      .catch((error) => {
        console.error('Error adding new request:', error); // For debugging
      });
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Substitute Requests
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Request
      </Button>
      <List>
        {requests.map((request, index) => (
          <ListItem key={request._id || index}>
            <ListItemText 
              primary={`Class: ${request.class.title}, Instructor: ${request.requestingInstructor ? request.requestingInstructor.username : 'Unknown'}`} 
              secondary={`Date & Time: ${request.dateTime ? new Date(request.dateTime).toLocaleString() : 'Not Set'}, Status: ${request.status}`}
            />
            {request.comment && (
              <Typography variant="body2" color="textSecondary">
                Comment: {request.comment}
              </Typography>
            )}
            {user.isSubstitute && request.status === 'open' && (
              <Button onClick={() => handleAcceptRequest(request._id)}>Accept</Button>
            )}
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Substitute Request</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="classId-label">Class</InputLabel>
            <Select
              labelId="class-label"
              name="classId"
              value={newRequest.classId}
              label="Class"
              onChange={handleInputChange}
            >
              {classes.map((cls) => (
                <MenuItem key={cls._id} value={cls._id}>{cls.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              TextField={(props) => <TextField {...props} fullWidth margin="dense" />}
              label="Date & Time"
              value={newRequest.dateTime}
              onChange={(newValue) => {
                setNewRequest({ ...newRequest, dateTime: newValue });
              }}
            />
          </LocalizationProvider>
          <TextField
            margin="dense"
            name="comment"
            label="Comment"
            type="text"
            fullWidth
            variant="standard"
            value={newRequest.comment}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SubstituteRequestsPage;
