//AuthProvider
import React, { useContext, createContext, useState, useEffect } from 'react';
import API from '../api/api'; // Import your API module

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const fetchUserData = async (token) => {
    try {
      console.log('Fetching user data with token:', token);
      const response = await API.get('/users/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('User data fetched:', response.data);
      return response.data.user;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };

  const login = async (token) => {
    console.log('Login called with token:', token);
  
    try {
      const userData = await fetchUserData(token);
      console.log('Response from fetchUserData:', userData);
  
      if (userData) {
        localStorage.setItem('token', token);
        setUser(userData);
        console.log('User logged in:', userData);
      } else {
        console.error('Invalid token or unable to fetch user data');
        // Consider clearing the token if it's invalid
        localStorage.removeItem('token');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle the error appropriately
      localStorage.removeItem('token');
    }
  };
  

  const logout = () => {
    console.log('Logout called');
    localStorage.removeItem('token');
    setUser(null);
    console.log('User logged out');
  };

  useEffect(() => {
    console.log('useEffect in AuthProvider');
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      fetchUserData(storedToken).then(userData => {
        if (userData) {
          setUser(userData);
          console.log('User successfully loaded from localStorage:', userData);
        } else {
          logout();
        }
      });
    }
  }, []);

  console.log('AuthProvider rendering with user:', user);


  const contextValue = {
    user,
    login,
    logout,
    isLoggedIn: Boolean(user),
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  console.log('useAuth called, context:', context);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
