//classes
import React, { useEffect, useState } from 'react';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';

const ClassesPage = () => {
  const [classes, setClasses] = useState([]);
  const [users, setUsers] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [open, setOpen] = useState(false);
  const [newClass, setNewClass] = useState({ title: '', description: '', instructor: [] });
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    API.get('/classes')
    .then(response => {
      console.log('Loaded classes:', response.data.docs); // Log loaded classes
      setClasses(response.data.docs);
    })
    .catch(error => {
      console.error('Failed to load classes:', error);
    });

  API.get('/users')
    .then(response => {
      console.log('Loaded users:', response.data.docs); // Log loaded users
      const filteredInstructors = response.data.docs.filter(user => user.role === 'instructor');
      console.log('Filtered instructors:', filteredInstructors); // Log filtered instructors
      setInstructors(filteredInstructors);
    })
    .catch(error => {
      console.error('Failed to load users:', error);
    });
}, [isLoggedIn, navigate]);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleInputChange = (e) => {
  setNewClass({ ...newClass, [e.target.name]: e.target.value });
  console.log('Form change:', { [e.target.name]: e.target.value }); // Log form changes
};

const handleInstructorsChange = (event) => {
  const value = event.target.value;
  // If the value is an array, use it directly, else spread it into a new array
  setNewClass({ ...newClass, instructor: typeof value === 'string' ? value.split(',') : value });
};

const handleSubmit = () => {
  console.log('Submitting new class:', newClass); // Log submission data
  API.post('/classes', newClass)
    .then(response => {
      console.log('New class added:', response.data); // Log response
      setClasses([...classes, response.data]);
      handleClose();
    })
    .catch(error => {
      console.error('Error adding new class:', error);
    });
};

  return (
    <Container>
      <Typography variant="h3" gutterBottom>Classes</Typography>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Class
      </Button>
      <Grid container spacing={3}>
        {classes.map((classInfo, index) => (
          <Grid item xs={12} sm={6} md={4} key={classInfo._id || index}>
            <Card>
              <CardContent>
                <Typography variant="h5">{classInfo.title}</Typography>
                {/* Add more class details */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Class</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="instructors-label">Instructors</InputLabel>
            <Select
              labelId="instructors-label"
              multiple
              value={newClass.instructor}
              onChange={handleInstructorsChange}
              renderValue={(selected) => 
                selected.map((id) => instructors.find(instructor => instructor._id === id).username).join(', ')
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
              {instructors.map((instructor) => (
                <MenuItem key={instructor._id} value={instructor._id}>
                  <Checkbox checked={newClass.instructor.indexOf(instructor._id) > -1} />
                  <ListItemText primary={instructor.username} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ClassesPage;
