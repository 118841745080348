//index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext';

const root = document.getElementById('root');
const rootInstance = ReactDOM.createRoot(root);

console.log('Rendering root with AuthProvider and App');

rootInstance.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
