// src/pages/HomePage.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const HomePage = () => {
  return (
    <Container>
      <Typography variant="h2" component="h1" gutterBottom>
        Welcome to Sub finder!
      </Typography>
      <Typography variant="body">
        Lets help you find a substite
      </Typography>
      {/* Add more content as needed */}
    </Container>
  );
};

export default HomePage;
