import React, { useEffect, useState } from 'react';
import API from '../api/api';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Select, MenuItem, InputLabel, FormControl, ListItemText, OutlinedInput } from '@mui/material';
import { Container, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';


const InstructorsPage = () => {
  const [instructors, setInstructors] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [open, setOpen] = useState(false);
  const [newInstructor, setNewInstructor] = useState({
    username: '',
    name: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    password: '',
    isSubstitute: false,
  });
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || user.role !== 'manager') {
      navigate('/login');
      return;
    }
    fetchData();
  }, [isLoggedIn, navigate, user.role]);




const columns = [
  { field: 'username', headerName: 'Username', width: 150 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'lastname', headerName: 'Last Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
  { field: 'classTitles', headerName: 'Classes', width: 200 },

  // Add more columns as needed
];

const fetchData = async () => {
  try {
    // Fetch classes
    const classesResponse = await API.get('/classes');
    const classesData = classesResponse.data.docs;
    setClasses(classesData);

    // Fetch instructors
    const instructorsResponse = await API.get('/users');
    const instructorsData = instructorsResponse.data.docs;
    const filteredInstructors = instructorsData.filter(user => user.role === 'instructor');

    // Map class IDs to class titles for each instructor
    const updatedInstructors = filteredInstructors.map(instructor => {
      const classTitles = instructor.classes.map(classId => 
        classesData.find(cls => cls._id === classId)?.title || classId
      );
      return { ...instructor, classTitles: classTitles.join(', ') };
    });

    setInstructors(updatedInstructors);
  } catch (error) {
    console.error('Failed to load data:', error);
  }
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewInstructor({ ...newInstructor, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setNewInstructor({ ...newInstructor, [e.target.name]: e.target.checked });
  };

  const handleClassChange = (event) => {
    setSelectedClasses(event.target.value);
  };


  const handleSubmit = async () => {
    // Fetch full class details first
    const fullClassDetailsPromises = selectedClasses.map(classId => 
      API.get(`/classes/${classId}`)
    );
    const fullClassDetailsResponses = await Promise.all(fullClassDetailsPromises);
    const fullClassDetails = fullClassDetailsResponses.map(res => res.data);
  
    // Prepare the instructor data
    const instructorData = {
      ...newInstructor,
      classes: selectedClasses
    };
  
    console.log('Adding new instructor:', instructorData);
    try {
      const response = await API.post('/users/register', instructorData);
      console.log('Response from adding instructor:', response.data);
  
      // Update the new instructor object with full class details
      const updatedInstructor = {
        ...response.data,
        classes: fullClassDetails.map(cls => cls.title).join(', '), // Join class titles for display
        id: response.data._id // Ensure to include 'id' field for DataGrid
      };
  
      // Update state with new instructor
      setInstructors(prevInstructors => [...prevInstructors, updatedInstructor]);
      
      await fetchData();

      // Reset state and close dialog
      setNewInstructor({ username: '', name: '', lastname: '', email: '', phoneNumber: '', password: '', isSubstitute: false });
      setSelectedClasses([]);
      handleClose();
    } catch (error) {
      console.error('Error adding new instructor:', error);
    }
  };
  
  

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Instructors
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Instructor
      </Button>
      <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
        <DataGrid
          autoHeight
          rows={instructors.map(instructor => ({ ...instructor, id: instructor._id }))}
          columns={columns}
          pageSize={10}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Instructor</DialogTitle>
        <DialogContent>
          <TextField name="username" label="Username" onChange={handleInputChange} fullWidth margin="dense" />
          <TextField name="name" label="Name" onChange={handleInputChange} fullWidth margin="dense" />
          <TextField name="lastname" label="Last Name" onChange={handleInputChange} fullWidth margin="dense" />
          <TextField name="email" label="Email" type="email" onChange={handleInputChange} fullWidth margin="dense" />
          <TextField name="phoneNumber" label="Phone Number" onChange={handleInputChange} fullWidth margin="dense" />
          <TextField name="password" label="Password" type="password" onChange={handleInputChange} fullWidth margin="dense" />
          <FormControl fullWidth margin="dense">
            <InputLabel>Classes</InputLabel>
            <Select
              multiple
              value={selectedClasses}
              onChange={handleClassChange}
              input={<OutlinedInput label="Classes" />}
              renderValue={(selected) => selected.map(id => classes.find(cls => cls._id === id)?.title).join(', ')}
            >
              {classes.map((cls) => (
                <MenuItem key={cls._id} value={cls._id}>
                  <Checkbox checked={selectedClasses.includes(cls._id)} />
                  <ListItemText primary={cls.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox name="isSubstitute" onChange={handleCheckboxChange} />}
            label="Is Substitute"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default InstructorsPage;
