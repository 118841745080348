// src/pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import API from '../api/api';

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      // If the user is already logged in, redirect to the home page or dashboard
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Resetting error on new submission
    try {
      const response = await API.post('/users/login', credentials);
      console.log('Response from login API:', response.data); // Log the entire response for more details
      const token = response.data.token;
      console.log('Token received from login API:', token);
      if (!token) {
        console.error('Token is undefined or null. Check the response from the login API.');
        return;
      }
      login(token); // Update context state
      console.log('Token stored in localStorage:', token);
      navigate('/'); // Redirect to home page or dashboard after successful login
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed');
      console.error('Error during login:', error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            label="Username"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            margin="normal"
            fullWidth
            autoFocus
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            margin="normal"
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default LoginPage;
