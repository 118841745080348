// Updated App component
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NavigationBar from './pages/components/NavigationBar';
import InstructorsPage from './pages/InstructorsPage';
import ClassesPage from './pages/ClassesPage';
import SubstituteRequestsPage from './pages/SubstituteRequestsPage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import { useAuth } from './context/AuthContext';
import { Box } from '@mui/material';

function App() {
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Router>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <NavigationBar />
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/instructors" element={isLoggedIn ? <InstructorsPage /> : <Navigate to="/login" />} />
            <Route path="/classes" element={isLoggedIn ? <ClassesPage /> : <Navigate to="/login" />} />
            <Route path="/subrequests" element={isLoggedIn ? <SubstituteRequestsPage /> : <Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
