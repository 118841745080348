// Updated navigationbar
import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const { isLoggedIn, logout } = useAuth(); // Destructure the logout method from useAuth

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const handleAuthAction = () => {
    if (isLoggedIn) {
      logout();
      navigate('/login'); // Redirect to login after logging out
    } else {
      navigate('/login'); // Redirect to login if not logged in
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white' }}>

<Typography variant="h6" sx={{ padding: 2, textAlign: 'left' }}>Sub Finder</Typography>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="primary"

          sx={{ borderRight: 1, borderColor: 'divider' }}
          >
          <Tab label="Home" value="/" />
          <Tab label="Instructors" value="/instructors" />
          <Tab label="Classes" value="/classes" />
          <Tab label="Substitute Requests" value="/subrequests" />
        </Tabs>
        <Box sx={{ flexGrow: 1 }}></Box> {/* This will push the logout button to the bottom */}
        <Button 
        onClick={handleAuthAction}
        sx={{ color: 'black', marginTop: 'auto', marginBottom: 2 }}
      >
        {isLoggedIn ? 'Log Out' : 'Log In'}
      </Button>
    </Box>
  );
};

export default NavigationBar;
