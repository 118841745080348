// src/api/api.js
import axios from 'axios';


if (process.env.NODE_ENV === 'test') {
  // Jest-specific code
  jest.mock('axios');
  axios.create.mockReturnThis(); // Mocks axios.create
axios.interceptors.request.use.mockImplementation((x) => x); // Mocks the interceptor

}
// At the top of your test file

// In your test cases


const API = axios.create({ baseURL: 'https://thesubfinder-16fa25bdbf1c.herokuapp.com/api/' });

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  console.log('Token from localStorage:', token);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    console.log('No token found in localStorage. Requests will be unauthenticated.');
  }

  console.log('Headers:', config.headers);
  return config;
});

export default API;
